import React, { useState, useEffect } from 'react';
import './Group.css'
import { useParams } from "react-router-dom";
import AddStudentForm from './AddStudentForm';
import RankCell from './RankCell';
import { get, put } from './endpoint';

function useGroup(all, groupName, reqSeq) {
    const [group, setGroup] = useState([])

    useEffect(() => {
        get(all ? `/grades` : `/grades/${groupName}`)
        .then(response => response.json())
        .then(result => {
            result.students.sort((s1, s2) => s1.lastName > s2.lastName)
            setGroup(result)
        })
    }, [all, groupName, reqSeq])

    return group;
}

function Group({ all, setGroupSelectState }) {
    const { groupName } = useParams()
    useEffect(() => {
        setGroupSelectState({admin: false, all, groupName})
    }, [setGroupSelectState, all, groupName])

    const [reqSeq, setReqSeq] = useState(0)
    const [filter, setFilter] = useState("")
    const [filteredGroup, setFilteredGroup] = useState([])
    const group = useGroup(all, groupName, reqSeq)
    const [examClicked, setExamClicked] = useState({studentId: undefined, examName: undefined})
    const [notes, setNotes] = useState("")
    const notesRef = React.useRef();

    function reloadGroup() {
        setReqSeq(reqSeq + 1)
    }

    useEffect(() => {
        if (group.length === 0) {
            return;
        }
        setNotes(group.notes ?? "");
        const trimedFilter = filter.trim().toLowerCase();
        if (trimedFilter !== "") {
            setFilteredGroup(group.students.filter(student => {
                return `${student.id}`.toLowerCase().startsWith(trimedFilter) || student.firstName.toLowerCase().startsWith(trimedFilter) || student.lastName.toLowerCase().startsWith(trimedFilter)
            }))
        } else {
            setFilteredGroup(group.students)
        }
    }, [filter, group])

    if (group.length === 0) {
        return <div></div>;
    }
    
    function resizeNotes() {
        notesRef.current.style.height = "inherit";
        let newHeight = notesRef.current.scrollHeight;
        notesRef.current.style.height = newHeight < 30 ? "30px" : `${newHeight}px`;
    }

    function changeNotes(evt) {
        setNotes(evt.target.value)
        resizeNotes()
        put(`/grades/${groupName}/notes`, { notes: evt.target.value })
        .catch(()=>{})
    }

    return <div>
        {all ? <div></div> : <div>{group.responsibleLogin ? `Group responsible: ${group.responsibleLogin}` : 'No responsible assigned.'}</div>}
        <textarea 
            style={{display: all ? "none" : "block"}}
            ref={notesRef}
            className="groupNotes"
            onChange={changeNotes}
            onFocus={resizeNotes}
            onBlur={evt => evt.target.style.height = "30px" }
            placeholder='Notes for this group...'
            value={notes}
        />
        <input type="text" style={{width: "60%", marginTop: "30px", marginBottom:"20px"}} placeholder="Filter by student ID, first name or last name" onChange={evt => setFilter(evt.target.value)} value={filter}/>
        <table className="Group-table" cellPadding="10" cellSpacing="0" width="100%">
            <thead>
                <tr align="left">
                    <th width="14%">ID</th>
                    <th width="25%">First name</th>
                    <th width="25%">Last name</th>
                    {group.exams.map(exam => <th key={exam.name}>{exam.name}</th>)}
                    <th>Average</th>
                </tr>
            </thead>
            <tbody>
                {filteredGroup.map((student, sIdx) => 
                    <tr key={student.id} className={examClicked.studentId === student.id ? 'updateStudentRank' : ''}>
                        <td>{student.id}</td>
                        <td>{student.firstName}</td>
                        <td>{student.lastName}</td>
                        {group.exams.map((exam, eIdx) => <RankCell
                            key={student.id + exam.name}
                            previousStudentId={sIdx === 0 ? undefined : filteredGroup[sIdx - 1].id}
                            nextStudentId={sIdx < filteredGroup.length - 1 ? filteredGroup[sIdx + 1].id : undefined}
                            studentId={student.id} 
                            firstExamName={group.exams[0].name}
                            latestExamName={group.exams[group.exams.length - 1].name}
                            previousExamName={eIdx === 0 ? undefined : group.exams[eIdx - 1].name}
                            nextExamName={eIdx < group.exams.length - 1 ? group.exams[eIdx + 1].name : undefined}
                            examName={exam.name} 
                            examClicked={examClicked}
                            setExamClicked={setExamClicked}
                            reloadGroup={reloadGroup}
                            grade={student.grades ? student.grades[exam.name] : ""}
                        />)}
                        <td>{student.avg}</td>
                    </tr>)}
            </tbody>
        </table>
        {all ? <div></div> : <AddStudentForm reloadGroup={reloadGroup} groupName={groupName}/>}
    </div>
}

export default Group;