import { authenticate  } from "./endpoint"
import React from 'react';

export default function Login(props) {
    const loginRef = React.useRef()
    const passwordRef = React.useRef()

    function handleSubmit(evt) {
        evt.preventDefault()
        const login = loginRef.current.value;
        const password = passwordRef.current.value;

        authenticate(login, password, authentication => {
            if (authentication) {
                props.setAuthentication(authentication)
            } else {
                loginRef.current.value = "";
                passwordRef.current.value = "";
            }
        })
    }

    return <div style={{margin: "auto", width: "70%"}}>
        <h1 style={{ textAlign: "center", width: "100%" }}>Ranks</h1>
        <form onSubmit={handleSubmit} style={{marginTop: "50px", textAlign: "center"}}>
            <input width="5" type="text" placeholder="Login" ref={loginRef}/>
            <br/>
            <input width="5" type="password" placeholder="Password" ref={passwordRef}/>
            <br/>
            <br/>
            <input type="submit" value="Login" style={{border: "0px"}}/>
        </form>
    </div>
}