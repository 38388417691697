import React, { useState } from 'react';
import { post } from './endpoint'

export default function AddStudentForm(props) {    
    function handleChange(e, setVal) {
        setVal(e.target.value)
    }

    const [id, setId] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const idRef = React.useRef()
    const firstNameRef = React.useRef()
    const lastNameRef = React.useRef()

    function handleSubmit(e) {
        e.preventDefault()
        if (id === "") {
            idRef.current.focus()
        } else if (firstName === "") {
            firstNameRef.current.focus()
        } else if (lastName === "") {
            lastNameRef.current.focus()
        } else {
            post(`/student`, {
                id,
                firstName,
                lastName,
                groupName: props.groupName
            })
            .then(response => { 
                props.reloadGroup()
            })
            .catch(console.error)
            .finally(() => {
                setFirstName("")
                setLastName("")
                setId("")
                idRef.current.blur()
                lastNameRef.current.blur()
                firstNameRef.current.blur()
            })
        }
    }

    return <form onSubmit={handleSubmit} style={{marginTop: "30px"}}>
            <legend>Add a missing student in this group</legend>
            <input style={{width: "11%"}} type="text" placeholder="student id" value={id} onChange={e => handleChange(e, setId)} ref={idRef}/>
            <input style={{width: "22%"}} type="text" placeholder="student first name" value={firstName} onChange={e => handleChange(e, setFirstName)} ref={firstNameRef}/>
            <input style={{width: "22%"}} type="text" placeholder="student last name" value={lastName} onChange={e => handleChange(e, setLastName)} ref={lastNameRef}/>
            <input type="submit" value="Add student"/>
    </form>
}