import './App.css';
import Group from './Group'
import Admin from './Admin'
import GroupSelector from './GroupSelector';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from './Login'
import { get } from './endpoint';

function App() {
  const [groupSelectState, setGroupSelectState] = useState({admin: false, all: false, groupName: ""})
  const [authentication, setAuthentication] = useState(undefined);
  const [defaultGroup, setDefaultGroup] = useState(undefined)

  useEffect(() => {
    if (!authentication) {
      get('/user').then(response => response.json()).then(setAuthentication).catch(_ => {})
      return;
    }
  
    get('/groups').then(response => response.json()).then(groups => {
      for (let group of groups) {
        if (group.responsibleLogin == authentication.login) {
          setDefaultGroup(`/grades/${group.name}`)
          return;
        }
      }
    })
  }, [authentication])

  if (!authentication) {
    return <Login setAuthentication={setAuthentication}/>
  }


  return <div>
    <div style={{ 
      display: process.env.NODE_ENV == "development" ? "block" : "none",
      textAlign:"left",
      width: "100%",
      backgroundColor: "red",
      whiteSpace: "nowrap",
      position: "fixed",
      top: "0px",
      height: "10px",
      padding: "4px",
      borderStyle: "dashed",
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='0' y='15' font-size='20'>DEV BUILD </text></svg>`
    }}>
    
    </div>
    <div style={{margin: "auto", width: "70%"}}>
      <h1 style={{ textAlign: "center", width: "100%" }}>Ranks</h1>
      <Router>
        <GroupSelector groupSelectState={groupSelectState} admin={authentication.admin}/>
        <Switch>
          <Route path="/grades/:groupName">
            <Group all={false} setGroupSelectState={setGroupSelectState}/>
          </Route>
          <Route path="/grades">
            <Group all={true} setGroupSelectState={setGroupSelectState}/>
          </Route>
          <Route path="/admin">
            <Admin admin={authentication.admin} setGroupSelectState={setGroupSelectState}/>
          </Route>
          <Route path="/">
            {defaultGroup ? <Redirect to={defaultGroup}/> : <Group all={true} setGroupSelectState={setGroupSelectState}/>}
          </Route>
        </Switch>
      </Router>
    </div>
  </div>
}

export default App;
