const addr = process.env.REACT_APP_SERVER_ADDR

function endpoint(path, method, body) {
    return new Promise((resolve, reject) => {
        const postCSV = body && body.type === 'text/csv';
        const payload = {
            method,
            credentials: 'include',
            headers: {
                'Content-Type': postCSV ? 'text/csv' : 'application/json',
            }
        };
        if (body) {
            payload.body = postCSV ? body : JSON.stringify(body)
        }
        fetch(addr + path, payload)
        .then(response => {
            if (response.ok) {
                resolve(response)
            } else {
                response.json().then(({code, msg}) => alert(code + ': ' + msg))
                reject(response)
            }
        })
        .catch(error => {
            console.log('err')
            reject(error)
        })
    })
}

export function get(path) {
    return endpoint(path, 'GET')
}

export function post(path, body) {
    return endpoint(path, 'POST', body)
}

export function put(path, body) {
    return endpoint(path, 'PUT', body)
}

export function del(path) {
    return endpoint(path, 'DELETE')
}

export function authenticate(login, password, callback) {
    endpoint('/auth', 'POST', {login, password})
    .then(response => response.json())
    .then(result => callback({admin: result.admin, login: result.login})) 
    .catch(_ => callback(undefined))
}
