import React, { useEffect, useState } from 'react';
import { get, post, put, del } from './endpoint';

function AdminInput({size, value, updateFn}) {
    const [inputValue, setInputValue] = useState(value);

    function handleChange(newValue) {
        function decimalSeparator(c) {
            return c === ',' || c === '.';
        }

        if (newValue.length > 1 && newValue[newValue.length - 1] === '.' && !decimalSeparator(newValue[newValue.length - 2])) {
            setInputValue(newValue)
        } else if (newValue.length > 1 && newValue[newValue.length - 1] === ',' && !decimalSeparator(newValue[newValue.length - 2])) {
            setInputValue(newValue.replace(/,/,'.'))
        } else if (!isNaN(newValue)) {
            setInputValue(parseFloat(newValue.replace(/,/,'.')))
        } else {
            setInputValue(value)
        }
    }

    return <input type="text" size={size} value={inputValue} onBlur={updateFn} onChange={e => handleChange(e.currentTarget.value)}/>
}

function Admin({admin, setGroupSelectState}) {
    useEffect(() => {
        setGroupSelectState({admin: true, all: false, groupName: ""})
    }, [setGroupSelectState, admin])
    const uploadInputRef = React.useRef()

    const newExamNameRef = React.useRef()
    const newExamRatioRef = React.useRef()
    
    const newUserLoginRef = React.useRef()
    const newUserPasswordRef = React.useRef()
    
    const [exams, setExams] = useState([])
    const [users, setUsers] = useState([])
    const [groups, setGroups] = useState([])
    
    const [examReqSeq, setExamReqSeq] = useState(0)
    const [userReqSeq, setUserReqSeq] = useState(0)

    useEffect(() => {
        get('/exams')
        .then(response => response.json())
        .then(result => { setExams(result) })
    }, [examReqSeq])
    
    useEffect(() => {
        get('/users')
        .then(response => response.json())
        .then(
            result => { 
                setUsers(result);
                get('/groups')
                .then(response => response.json())
                .then(result => {setGroups([]);setGroups(result) })
            },
            error => { console.error('something went wrong when fetching users', error) })
    }, [userReqSeq])

    if (!admin) {
        return <div></div>;
    }

    const downloadAllRanks = () => {
        get('/exportCSV')
        .then(res => res.blob())
        .then(async blob => {
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            a.download = `ranks-${new Date().toJSON()}.csv`;
            a.click();
        })
    };

    const uploadNewStudents = e => {
        e.preventDefault();

        if (!uploadInputRef.current.files || uploadInputRef.current.files.length == 0) {
            alert('Please add a CSV file to upload first.')
            return;
        }

        if (!window.confirm('Uploading a new set of student will erase:\n- all the existing students\n- all the associated ranks\n- all the existing groups\nContinue?')) {
            return
        }

        put('/importCSV', uploadInputRef.current.files[0])
        .then(_ => {
            setGroupSelectState({admin: true, all: false, groupName: ""})
            alert('New student uploaded')
        })
    }

    const putExam = (name, ratio) => {
        put('/exam', { name, ratio })
        .catch(() => {})
        .then(() => {
            setExamReqSeq(examReqSeq +1 )
            newExamNameRef.current.value = "";
            newExamRatioRef.current.value = "";
        })
    }

    const deleteExam = name => {
        if (!window.confirm(`Deleting the exam "${name}" will also erase all existing ranks of this exam for all students. Continue?`)) {
            return
        }
        del(`/exam/${name}`).then(() => setExamReqSeq(examReqSeq + 1))
    }

    const setResponsible = (group, login) => {
        put(`/group/${group}/responsible`, { responsibleLogin: login })
        .catch(() => {})
        .finally(() => setUserReqSeq(userReqSeq + 1))
    }

    const addUser = () => {
        post('/user', {
            login: newUserLoginRef.current.value,
            password: newUserPasswordRef.current.value,
            admin: 0
        })
        .catch(() => {})
        .finally(() => {
            setUserReqSeq(userReqSeq + 1)
            newUserLoginRef.current.value = "";
            newUserPasswordRef.current.value = "";
        } )
    };

    const deleteUser = login => {
        del(`/user/${login}`)
        .catch(() => {})
        .finally(() => {
            setUserReqSeq(userReqSeq + 1)
        })
    }

    return <div>
        <div>
            <h4>Import/export</h4>
            <input type="file" ref={uploadInputRef}/>
            <input type="submit" value="Upload new students" onClick={uploadNewStudents}/>
            <br/>
            <br/>
            <input type="submit" onClick={downloadAllRanks} value="Download all ranks (CSV)"/>
        </div>

        <div style={{marginTop: "50px"}}>
            <h4>User management</h4>
            <table className="Group-table" cellPadding="10" cellSpacing="0" width="50%">
                <thead>
                    <tr align="left">
                        <th width="80%">User login</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => <tr height="55" key={user.login}>
                        <td>{user.login}</td>
                        <td>{user.admin ? <div/> : <input type="submit" value="Delete" onClick={() => deleteUser(user.login)}/> }</td>
                    </tr>)}
                    <tr>
                        <td>
                            <input type="text" size="15" placeholder="login" ref={newUserLoginRef}/>
                            <input type="password" size="15" placeholder="password" ref={newUserPasswordRef}/>
                        </td>
                        <td>
                            <input type="submit" value="Add" onClick={addUser}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div style={{marginTop: "50px"}}>
            <h4>Group responsible management</h4>
            <table className="Group-table" cellPadding="10" cellSpacing="0" width="50%">
                <thead>
                    <tr align="left">
                        <th width="50%">Group name</th>
                        <th width="50%">Responsible</th>
                    </tr>
                </thead>
                <tbody>
                    {groups.map(group => <tr key={group.name}>
                        <td>{group.name}</td>
                        <td>
                            <select 
                                size="1" 
                                style={{width: "180px"}} 
                                onChange={e => setResponsible(group.name, e.target.value)}
                                defaultValue={group.responsibleLogin ? group.responsibleLogin : ""}
                            >
                                {users.map(user => <option key={`${group.name}${user.login}`} value={user.login}>{user.login}</option>)}
                                <option disabled value="">No responsible assigned</option>
                            </select>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>

        <div style={{marginTop: "50px"}}>
            <h4>Exam management</h4>
            <table className="Group-table" cellPadding="10" cellSpacing="0" width="50%">
                <thead>
                    <tr align="left">
                        <th width="80%">Exam name</th>
                        <th>Ratio</th> 
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {exams.map(exam => <tr key={exam.name}>
                        <td>
                            {exam.name}
                        </td>
                        <td>
                            <AdminInput size="4" value={exam.ratio} updateFn={e => putExam(exam.name, e.currentTarget.value)}/>
                        </td>
                        <td><input type="submit" value="Delete" onClick={() => deleteExam(exam.name)}/></td>
                    </tr>)}
                    <tr>
                        <td>
                            <input type="text" size="35" placeholder="name" ref={newExamNameRef}/>
                        </td>
                        <td>
                            <input type="text" size="4" placeholder="ratio" ref={newExamRatioRef}/>
                        </td>
                        <td>
                            <input type="submit" value="Add" onClick={() => putExam(newExamNameRef.current.value, newExamRatioRef.current.value)}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
}

export default Admin;