import React, { useEffect, useState } from 'react';
import { put, del } from './endpoint';

export default function RankCell(props) {
    const [rankValue, setRankValue] = useState(props.grade ?? '')
    const inputRef = React.useRef();

    function makeExamClicked(studentId, examName) {
        return {studentId, examName}
    }

    function sendRank(value) {
        console.log(props.grade, value);

        if (props.grade != undefined && props.grade != '') {
            if (value == '' && !window.confirm(props.grade + ' You are about to remove a student grade - continue?')) {
                setRankValue(props.grade)
                return;
            }
        }

        if (parseFloat(value) !== parseFloat(props.grade) && !(isNaN(parseFloat(value)) && isNaN(parseFloat(props.grade)))) {
            let req = function() {
                let endpoint = `/student/${props.studentId}/grade/${props.examName}`;
                if (value === '') {
                    return del(endpoint);
                } else {
                    return put(endpoint, { grade: value })
                }
            }();
            
            req.then(response => {
                props.reloadGroup()
            })
            .catch(error => {
                console.log(error)
                setRankValue(isNaN(props.grade) ? '' : props.grade)
            })
        }
    }

    function handleKeyDown(evt) {
        if (evt.key === 'Escape') {
            evt.preventDefault();
            setRankValue(props.grade)
            props.setExamClicked(makeExamClicked())
        } else if (evt.key === 'Enter' || evt.key === 'Tab') {
            evt.preventDefault();
            props.setExamClicked(makeExamClicked());
        } else if (evt.key === 'ArrowUp') {
            evt.preventDefault();
            props.setExamClicked(props.previousStudentId ? makeExamClicked(props.previousStudentId, props.examName) : makeExamClicked());
        } else if (evt.key === 'ArrowDown') {
            evt.preventDefault();
            props.setExamClicked(props.nextStudentId ? makeExamClicked(props.nextStudentId, props.examName) : makeExamClicked())
        } else if (evt.key === 'ArrowLeft') {
            evt.preventDefault();
            if (props.previousExamName) {
                props.setExamClicked(makeExamClicked(props.studentId, props.previousExamName))
            } else if (props.previousStudentId) {
                props.setExamClicked(makeExamClicked(props.previousStudentId, props.latestExamName))
            } else {
                props.setExamClicked(makeExamClicked())
            }
        } else if (evt.key === 'ArrowRight') {
            evt.preventDefault();
            if (props.nextExamName) {
                props.setExamClicked(makeExamClicked(props.studentId, props.nextExamName))
            } else if (props.nextStudentId) {
                props.setExamClicked(makeExamClicked(props.nextStudentId, props.firstExamName))
            } else {
                props.setExamClicked(makeExamClicked())
            }
        }
    }

    function handleBlur(evt) {
        sendRank(evt.target.value)
        if (isSelected()) {
            props.setExamClicked(makeExamClicked())
        }
    }

    function handleChange(evt) {
        function decimalSeparator(c) {
            return c === ',' || c === '.';
        }

        const value = evt.target.value
        if (value.length === 0) {
            setRankValue('')
        } else if (value.length > 1 && value[value.length - 1] === '.' && !decimalSeparator(value[value.length - 2])) {
            setRankValue(value)
        } else if (value.length > 1 && value[value.length - 1] === ',' && !decimalSeparator(value[value.length - 2])) {
            setRankValue(value.replace(/,/,'.'))
        } else if (!isNaN(value)) {
            setRankValue(parseFloat(value.replace(/,/,'.')))
        } else {
            setRankValue(rankValue)
        }
    }

    function isSelected() {
        return props.examClicked.studentId === props.studentId && props.examClicked.examName === props.examName
    }

    useEffect(() => {
        if (isSelected()) {
            inputRef.current.focus()
        } else {
            inputRef.current.blur()
        }
    }, [props.examClicked])

    return <td onClick={() => props.setExamClicked(makeExamClicked(props.studentId, props.examName))}>
        <input
            type="text"
            size="1" 
            value={rankValue}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={inputRef}
        />
    </td>
}
