import './App.css';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './GroupSelector.css'
import { get } from './endpoint'

function GroupSelector(props) {
  const [groups, setGroups] = useState([])
  const groupSelectState = props.groupSelectState
  
  useEffect(() => {
    get('/groups')
    .then(response => response.json())
    .then(
      result => { setGroups(result) },
      error => { console.error('error', error) })
  }, [groupSelectState]);

  return <div className="GroupSelectorWrapper">
    {groups.map(group =>  <div className={`GroupSelector ${groupSelectState.groupName === group.name ? "GroupSelected" : ""}`} key={group.name}><Link to={`/grades/${group.name}`}>{group.name}</Link></div>)}
    <div className={`GroupSelector ${groupSelectState.all ? 'GroupSelected' : ''}`}><Link to="/grades">All groups</Link></div>
    {props.admin ? <div className={`GroupSelector ${groupSelectState.admin ? 'GroupSelected' : ''}`}><Link to="/admin">Admin</Link></div> : ''}
  </div>
}

export default GroupSelector;
